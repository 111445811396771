<template>
  <v-container class="min-height-100">
    <v-row class="min-height-100 auth-page register-page ma-0">
      <!-- Left section that's hidden on phones -->
      <v-col class="d-none d-md-flex" md="6">
        <div
          class="logo-container d-flex align-center px-12 cursor-pointer"
          :class="host.theme.type === 'dark' ? 'black' : 'white'"
          @click="$router.push({ name: 'Home' })"
        >
          <img :src="host.images.logo" />
        </div>
      </v-col>

      <!-- Right section that does have a form -->
      <v-col class="min-height-100" cols="12" md="6">
        <div class="text-right text-subtitle-2">
          Already have an account? &nbsp;
          <router-link class="text-decoration-none" :to="{ name: 'Login' }">
            Login
          </router-link>
        </div>

        <div class="form-container d-flex flex-column justify-center min-height-100">
          <div class="text-h5 font-weight-bold">
            Get started.
          </div>
          <div class="text-subtitle-2 mt-3 mb-8 grey--text text--darken-2">
            By registering, I agree to Klug
            <a href="https://klugklug.com/terms-and-conditions" target="_blank">Terms of Service</a>
            and
            <a href="https://klugklug.com/privacy-policy/" target="_blank">Privacy Policy</a>.
          </div>

          <v-form @submit.prevent="handleSubmit">
            <v-text-field
              v-model.trim="form.name"
              type="text"
              :error-messages="nameErrors"
              label="Name"
              outlined
              required
              @input="$v.form.name.$touch()"
              @blur="$v.form.name.$touch()"
            ></v-text-field>

            <v-text-field
              v-model.trim="form.email"
              type="email"
              :error-messages="emailErrors"
              label="Business Email"
              outlined
              required
              @input="$v.form.email.$touch()"
              @blur="$v.form.email.$touch()"
            ></v-text-field>

            <v-text-field
              v-model.trim="form.phone"
              type="tel"
              :error-messages="phoneErrors"
              label="Phone No."
              outlined
              required
              @input="$v.form.phone.$touch()"
              @blur="$v.form.phone.$touch()"
            ></v-text-field>

            <v-text-field
              v-model.trim="form.business_name"
              type="text"
              :error-messages="$v.form.business_name.$anyError ? ['Please enter a valid business name'] : null"
              label="Business Name"
              outlined
              required
              @input="$v.form.business_name.$touch()"
              @blur="$v.form.business_name.$touch()"
            ></v-text-field>

            <!-- <v-text-field
              v-model.trim="form.website_link"
              type="text"
              :error-messages="$v.form.website_link.$anyError ? ['Please enter a valid website link'] : null"
              label="Website Link"
              outlined
              required
              @input="$v.form.website_link.$touch()"
              @blur="$v.form.website_link.$touch()"
            ></v-text-field> -->

            <v-select
              v-model.trim="form.campaigns_yearly_count"
              :error-messages="$v.form.campaigns_yearly_count.$anyError ? ['Please select a value'] : null"
              label="How many campaigns do you run yearly?"
              :items="['10 - 100', '100 - 1000', '1000+']"
              outlined
              required
              @input="$v.form.campaigns_yearly_count.$touch()"
              @blur="$v.form.campaigns_yearly_count.$touch()"
            ></v-select>

            <v-textarea
              v-model.trim="form.registration_message"
              type="text"
              :error-messages="$v.form.registration_message.$anyError ? ['Please leave us a message'] : null"
              label="Message"
              outlined
              required
              @input="$v.form.registration_message.$touch()"
              @blur="$v.form.registration_message.$touch()"
            ></v-textarea>

            <v-text-field
              v-model.trim="form.password"
              type="password"
              :error-messages="passwordErrors"
              label="Password"
              outlined
              required
              @input="$v.form.password.$touch()"
              @blur="$v.form.password.$touch()"
            ></v-text-field>

            <v-text-field
              v-model.trim="form.password_confirmation"
              type="password"
              :error-messages="confirmPasswordErrors"
              label="Confirm Password"
              outlined
              required
              @input="$v.form.password_confirmation.$touch()"
              @blur="$v.form.password_confirmation.$touch()"
            ></v-text-field>

            <div class="d-flex justify-space-between align-center">
              <span></span>

              <v-btn
                :class="{ 'shadow--primary': !isLoading }"
                color="buttonPrimary"
                type="submit"
                large
                depressed
                :loading="isLoading"
                :disabled="isLoading"
              >
                Register
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { required, minLength, maxLength, email, numeric, sameAs, url } from "vuelidate/lib/validators"

const originalForm = () => ({
  name: "",
  email: "",
  phone: "",

  business_name: "",
  // website_link: "",
  campaigns_yearly_count: "",
  registration_message: "",

  password: "",
  password_confirmation: ""
})

const blacklistedDomains = [
  "gmail.com",
  "yahoo.com",
  "mail.com",
  "hotmail.com",
  "aol.com",
  "hotmail.co.uk",
  "hotmail.fr",
  "msn.com",
  "yahoo.fr",
  "wanadoo.fr",
  "orange.fr",
  "comcast.net",
  "yahoo.co.uk",
  "yahoo.com.br",
  "yahoo.co.in",
  "live.com",
  "rediffmail.com",
  "free.fr",
  "gmx.de",
  "web.de",
  "yandex.ru",
  "ymail.com",
  "libero.it",
  "outlook.com",
  "uol.com.br",
  "bol.com.br",
  "mail.ru",
  "cox.net",
  "hotmail.it",
  "sbcglobal.net"
]

export default {
  name: "Register",

  data: () => ({
    isLoading: false,
    form: originalForm()
  }),

  computed: {
    nameErrors () {
      // initiate the array of error messages
      const errors = []

      // if there has been no activity, show no error
      if (!this.$v.form.name.$dirty) return errors

      // else test it
      !this.$v.form.name.required && errors.push('Name is required')

      // return the error messages
      return errors
    },

    emailErrors () {
      // initiate the array of error messages
      const errors = []

      // if there has been no activity, show no error
      if (!this.$v.form.email.$dirty) return errors

      // else test it
      !this.$v.form.email.email && errors.push('Must be valid e-mail')
      !this.$v.form.email.required && errors.push('E-mail is required')

      // return the error messages
      return errors
    },

    phoneErrors () {
      // initiate the array of error messages
      const errors = []

      // if there has been no activity, show no error
      if (!this.$v.form.phone.$dirty) return errors

      // else test it
      !this.$v.form.phone.numeric && errors.push('Must be valid phone number')
      !this.$v.form.phone.required && errors.push('Phone number is required')

      // return the error messages
      return errors
    },

    passwordErrors () {
      // initiate the array of error messages
      const errors = []

      // if there has been no activity, show no error
      if (!this.$v.form.password.$dirty) return errors

      // else test it
      !this.$v.form.password.required && errors.push('Password is required')
      !this.$v.form.password.minLength && errors.push('Password must be 8 characters long')

      // return the error messages
      return errors
    },

    confirmPasswordErrors () {
      // initiate the array of error messages
      const errors = []

      // if there has been no activity, show no error
      if (!this.$v.form.password_confirmation.$dirty) return errors

      // else test it
      !this.$v.form.password_confirmation.required && errors.push('Please re-type your password')
      !this.$v.form.password_confirmation.sameAs && errors.push('Passwords do not match')

      // return the error messages
      return errors
    },
  },

  // Define the vuelidate validation rules
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(255)
      },
      email: {
        required,
        email,
        maxLength: maxLength(255)
      },
      phone: {
        required,
        numeric,
      },

      business_name: {
        required,
        maxLength: maxLength(255)
      },
      // website_link: {
      //   maxLength: maxLength(255)
      // },
      campaigns_yearly_count: {
        required
      },
      registration_message: {
        required,
        maxLength: maxLength(10_000)
      },

      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(200)
      },
      password_confirmation: {
        required,
        sameAs: sameAs('password')
      }
    }
  },

  methods: {
    async handleSubmit() {
      // disable the button
      this.isLoading = true

      // Run validations
      await this.$v.$touch()

      // If any validation error, stop the execution
      if (this.$v.$anyError) {
        this.isLoading = false

        return false
      }

      // Check if the email address is not using public providers
      const domain = this.form.email.trim().split("@")[1].toLocaleLowerCase()

      if (blacklistedDomains.includes(domain)) {
        this.$store.dispatch("toasts/add", {
          text: "Please use a business email address."
        })

        this.isLoading = false

        return false
      }

      // Show a loader while the request is being processed
      const loaderId = Symbol()
      await this.$store.dispatch("loaders/add", loaderId)

      try {
        await axios({
          url: "/api/register",
          method: "POST",
          data: this.form
        })

        // Fire a global event for anyone to listen
        window.dispatchEvent(new CustomEvent('signedUp', {
          detail: {
            module: "auth",
            origin: "Register/handleSubmit",
          }
        }))

        // Show alert
        this.$store.dispatch("toasts/add", { text: "Account Created." })

        // Redirect to Success [Hardcoded]
        window.location.href = "https://klugklug.com/thankyou879e6545sdf67123pa765789w2q/"
      } catch (error) {
        logger({ type: "Register Error", error })

        // show the error message to the user
        this.$store.dispatch("toasts/add", { text: "An Error Occurred." })
      } finally {
        // remove the loader
        await this.$store.dispatch("loaders/remove", loaderId)

        // enable the button
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="stylus" src="@/stylus/auth.styl"></style>
